import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

const Home_lazyPromise = import('./containers/Home');
let Home_lazy = lazy(() => Home_lazyPromise);

export default () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <Switch>
          <Route path='/' render={(props) => <Home_lazy {...props} />} />
          <Route render={() => <Redirect to='/' />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
