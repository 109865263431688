import ActionTypes from './constants';

export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.PopularsFetched:
      return { ...state, populars: payload.populars };
    case ActionTypes.FetchStores:
      return { ...state, stores: payload.stores };
    case ActionTypes.FetchOrderSetups:
      return { ...state, settings: payload.settings };
    case ActionTypes.FetchSuburbs:
      return { ...state, suburbs: payload.suburbs };
    default:
      return state;
  }
};
