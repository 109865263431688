import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppRoutes from './routes';
import { unregister } from './registerServiceWorker';

import Store from './store';

unregister();

ReactDOM.render(
  <Provider store={Store}>
    <AppRoutes />
  </Provider>,
  document.getElementById('root')
);
